export const stackedBarChartDataDashboard3 =[
  {
    name: 'Crater',
    data: [1850, 1895, 1875, 1702, 1609, 
          1513, 1487, 1480, 1855, 1707, 
          1633, 2028, 2183],
  }, {
    name: 'Elephant rock',
    data: [2670, 2351, 2526, 2148, 1963,
          2203, 2064, 2223, 2214, 2196,
          2257, 2586, 2374]
  }, {
    name: 'Historical site',
    data: [11174, 10667, 10549, 9835, 9210,
      8841, 8125, 9464, 9097, 9445,
      9867, 10907, 11449]
  }, {
    name: 'Maraya',
    data: [3936, 3479, 3462, 3360, 3479,
      3363, 2644, 2827, 3259, 3425,
      3644, 3252, 3614]

  }, {
    name: 'Old town',
    data: [9244, 8647, 7965, 7684, 7511,
      7454, 6952, 7632, 7264, 7781,
      7866, 8271, 8774]
  }]