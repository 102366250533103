/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

export const lineChartDataDashboard = [
  {
    name: "Historical site",
    data: [500, 250, 300, 220, 500, 250, 300, 230, 300, 350, 250, 400],
  },
  {
    name: "Maraya",
    data: [300, 230, 300, 350, 370, 420, 550, 350, 400, 500, 330, 550],
  },
  {
    name: "Elephant rock",
    data: [420, 330, 500, 370, 350, 300, 350, 230, 550, 400, 300, 550],
  },
  {
    name: "Crater",
    data: [350, 300, 500, 330, 420, 370, 550, 230, 400, 350, 300, 550],
  },
];
