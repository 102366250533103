import * as React from 'react';
import { styled, alpha, Box } from '@mui/system';
import { Slider as BaseSlider, sliderClasses } from '@mui/base/Slider';
import useMediaQuery from '@mui/material/useMediaQuery'

export default function RangeSlider({value, setValue}) {
  // const [value, setValue] = React.useState([1, 12]);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: isSmallScreen ? "100%": "57%", alignContent: 'right', marginRight: 1 }}>
      {/* controlled: */}
      <Slider
        value={value}
        onChange={handleChange}
        getAriaLabel={() => 'Temperature range'}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        min={0}
        max={12}
        // marks={marks}
        // step={null}
        // slots={{ valueLabel: SliderValueLabel }}
      />
      {/* uncontrolled: */}
      {/* <Slider
        defaultValue={[20, 37]}
        getAriaLabel={() => 'Temperature range'}
        getAriaValueText={valuetext}
        min={0}
        max={100}
      /> */}
    </Box>
  );
}

// function SliderValueLabel({ children }) {
//   return (
//     <span className="label">
//       <div className="value">{children}</div>
//     </span>
//   );
// }

// SliderValueLabel.propTypes = {
//   children: PropTypes.element.isRequired,
// };
const marks = [
  {label: '', value: 0},
  {label: '', value: 1},
  {label: '', value: 2},
  {label: '', value: 3},
  {label: '', value: 4},
  {label: '', value: 5},
  {label: '', value: 6},
  {label: '', value: 7},
  {label: '', value: 8},
  {label: '', value: 9},
  {label: '', value: 10},
  {label: '', value: 11},
  {label: '', value: 12}
];

function valuetext(value) {
  return 't';
}

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B3',
  900: '#003A75',
};
const yellow = {
  100: '#FFFBDA',
  200: '#F3EA99',
  300: '#FFE766',
  400: '#FFEB33',
  500: '#FFD600',
  600: '#E5CE00',
  700: '#B38C00',
  900: '#755B00',
};

const rcu = {
  100: '#FFFBDA',
  200: '#F3EA99',
  300: '#FFE766',
  400: '#FFEB33',
  500: '#FFD600',
  600: '#E5CE00',
  700: '#B38C00',
  900: '#755B00',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};
const orange = {
  100: '#FFECDA',
  200: '#F3CA99',
  300: '#FFAF66',
  400: '#FF7C33',
  500: '#FF9900',
  600: '#E5A500',
  700: '#6B7A90',
  800: '#B38100',
  900: '#754600',
};

const Slider = styled(BaseSlider)(
  ({ theme }) => `
  color: ${theme.palette.mode === 'light' ? orange[500] : orange[400]};
  height: 6px;
  width: 100%;
  padding: 16px 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  &.${sliderClasses.disabled} { 
    pointer-events: none;
    cursor: default;
    color: ${theme.palette.mode === 'light' ? grey[300] : grey[600]};
    opacity: 0.5;
  }

  & .${sliderClasses.rail} {
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: ${theme.palette.mode === 'light' ? orange[200] : orange[900]};
  }

  & .${sliderClasses.track} {
    display: block;
    position: absolute;
    height: 4px;
    border-radius: 2px;
    background-color: currentColor;
  }

  & .${sliderClasses.thumb} {
    position: absolute;
    width: 16px;
    height: 16px;
    margin-left: -6px;
    margin-top: -6px;
    box-sizing: border-box;
    border-radius: 50%;
    outline: 0;
    border: 3px solid currentColor;
    background-color: #fff;

    &:hover{
      box-shadow: 0 0 0 4px ${alpha(
        theme.palette.mode === 'light' ? orange[200] : orange[300],
        0.3,
      )};
    }
    
    &.${sliderClasses.focusVisible} {
      box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? orange[700] : orange[200]};
      outline: none;
    }

    &.${sliderClasses.active} {
      box-shadow: 0 0 0 5px ${alpha(
        theme.palette.mode === 'light' ? orange[200] : orange[300],
        0.5,
      )};
      outline: none;
    }
  }

  & .${sliderClasses.mark} {
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: currentColor;
    top: 50%;
    opacity: 0.7;
    transform: translateX(-50%);
  }

  & .${sliderClasses.markActive} {
    background-color: #fff;
  }
`,
);
