/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

export const barChartOptionsDashboard2 = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    theme: "dark",
  },
  tooltip: {
    style: {
      fontSize: "10px",
      fontFamily: "Plus Jakarta Display",
    },
    onDatasetHover: {
      style: {
        fontSize: "10px",
        fontFamily: "Plus Jakarta Display",
      },
    },
    theme: "dark",
  },
  legend: {
    enabled: false,
    position: 'top',
    horizontalAlign: 'left',
    labels: {
      colors: '#fff', // Set legend text color to white
      align: 'left',
    },
  },
  dataLabels: {
    enabled: true
  },
  xaxis: {
    categories: [
      'Dec 2022', 'Jan 2023', 'Feb 2023',
      'Mar 2023', 'Apr 2023', 'May 2023', 'Jun 2023', 'Jul 2023',
      'Aug 2023', 'Sep 2023', 'Oct 2023', 'Nov 2023', 'Dec 2023',
    ],
    show: false,
    labels: {
      show: true,
      style: {
        colors: "#fff",
        fontSize: "10px",
      },
    },
    axisBorder: {
      show: true,
      style: {
        color: 'black'
      },
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    color: "#fff",
    labels: {
      show: true,
      style: {
        colors: "#fff",
        fontSize: "10px",
        fontFamily: "Plus Jakarta Display",
      },
    },
  },
  grid: {
    show: false,
  },
  fill: {
    colors: ['#675500', '#f9ca24'],
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 2,
      // columnWidth: 30,
      dataLabels: {
        total: {
          enabled: false,
          style: {
            fontSize: '13px',
            fontWeight: 'normal',
            color: '#dfe6e9'
          }
        }
      }
    },
  },
  responsive: [
    {
      breakpoint: 0,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
          },
        },
      },
    },
  ],
  colors: ['#675500', '#f9ca24'],
  labels: ['Bus', 'Taxi / Car'],
};
