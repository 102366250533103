/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

function Footer() {
  return (
    <VuiBox
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="center"
      direction="row"
      component="footer"
      py={2}
      pb={0}
    >
      <VuiBox item xs={12} sx={{ textAlign: "center" }}>
        <VuiTypography
          variant="button"
          sx={{ 
            textAlign: "center",
            fontWeight: "normal !important",
            transition: 'color 0.1s ease', // Smooth transition over 0.3 seconds
            '&:hover': {
              color: 'white !important', // Text color change on hover
            },
            '&:hover a':{
              color: '#d35400 !important'
            }
          }}
          color="text"
        >
          Copyrights @ {new Date().getFullYear()} | Powered by <a style={{color: '#e67e22'}} href="https://mobisense.ai">MOBiSENSE</a> | All Rights Reserved
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
}

export default Footer;
