/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Card, LinearProgress, Stack } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import linearGradient from "assets/theme/functions/linearGradient";

// Vision UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// MediaQuery for current screen size info
import useMediaQuery from '@mui/material/useMediaQuery'

// Dashboard layout components
import WelcomeMark from "layouts/analytics/components/WelcomeMark";
import Projects from "layouts/analytics/components/Projects";
import OrderOverview from "layouts/analytics/components/OrderOverview";
import SatisfactionRate from "layouts/analytics/components/SatisfactionRate";
import ReferralTracking from "layouts/analytics/components/ReferralTracking";

// React icons
import { IoIosRocket } from "react-icons/io";
import { IoGlobe } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoWallet } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";

// Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import BarChart from "examples/Charts/BarCharts/BarChart";
import DonutChart from "examples/Charts/DonutCharts/DonutChart";
import { stackedBarChartDataDashboard } from "layouts/analytics/data/stackBarChartData";
import { stackedBarChartOptionsDashboard } from "layouts/analytics/data/stackBarChartOptions";
import { stackedBarChartDataDashboard2 } from "layouts/analytics/data/stackBarChartData2";
import { stackedBarChartOptionsDashboard2 } from "layouts/analytics/data/stackBarChartOptions2";
import { stackedBarChartDataDashboard3 } from "layouts/analytics/data/stackBarChartData3";
import { stackedBarChartOptionsDashboard3 } from "layouts/analytics/data/stackBarChartOptions3";
import { horizontalBarChartDataDashboard } from "layouts/analytics/data/horizontalBarChartData";
import { horizontalBarChartOptionsDashboard } from "layouts/analytics/data/horizontalBarChartOptions";
import { lineChartDataDashboard } from "layouts/analytics/data/lineChartData";
import { lineChartOptionsDashboard } from "layouts/analytics/data/lineChartOptions";
import { pieChartDataDashboard } from "layouts/analytics/data/pieChartData";
import { pieChartOptionsDashboard2 } from "layouts/analytics/data/pieChartOptions2";
import { barChartDataDashboard2 } from "layouts/analytics/data/barChartData2";
import { barChartOptionsDashboard2 } from "layouts/analytics/data/barChartOptions2";
import { donutChartDataDashboard } from "layouts/analytics/data/donutChartData";
import { donutChartOptionsDashboard } from "layouts/analytics/data/donutChartOptions";
import { donutChartOptionsDashboard2 } from "layouts/analytics/data/donutChartOptions2";
import { donutChartOptionsDashboard3 } from "layouts/analytics/data/donutChartOptions3";
import { donutChartOptionsDashboard4 } from "layouts/analytics/data/donutChartOptions4";
import { donutChartOptionsDashboard5 } from "layouts/analytics/data/donutChartOptions5";
import PieChart from "examples/Charts/PieCharts/PieChart";
import GaugeComponent from 'react-gauge-component'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CountUp from 'react-countup';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DirectionsCarFilled from '@mui/icons-material/DirectionsCarFilled'
import DirectionsCarIconOutlined from '@mui/icons-material/DirectionsCarOutlined'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoomOutlined'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import FreeBreakfastOutlinedIcon from '@mui/icons-material/FreeBreakfastOutlined';
import LaptopMacOutlinedIcon from '@mui/icons-material/LaptopMacOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DirectionsWalkOutlinedIcon from '@mui/icons-material/DirectionsWalkOutlined';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import StraightenIcon from '@mui/icons-material/Straighten';
import './test.css'
import RangeSlider from "./RangeSlider";
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';

function Dashboard() {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  // date range slider value
  const [value, setValue] = useState([1,12])

  const [xAxisCategories, setXAxisCategories] = useState([]);
  const [month, setMonth] = useState(null);
  const [sMonth, setSMonth] = useState(new Date(2023, 0));
  const [eMonth, setEMonth] = useState(new Date(2023, 11));
  const [sMonthIndex, setSMonthIndex] = useState(1);
  const [eMonthIndex, setEMonthIndex] = useState(12);
  const months = ['Dec 2022', 'Jan 2023', 'Feb 2023', 'Mar 2023', 'Apr 2023', 
                  'May 2023', 'Jun 2023', 'Jul 2023', 'Aug 2023', 'Sep 2023', 
                  'Oct 2023', 'Nov 2023', 'Dec 2023']
  const bus = [47, 45, 44, 0, 39, 38, 0, 0, 0, 0, 43, 45, 46]
  const taxi = [0, 0, 0, 30, 0, 0, 25, 27, 28, 29, 0, 0, 0]

  const tapc = [15, 15, 14, 14, 13, 12, 12, 12, 13, 14, 14, 14, 15] // chart 3 time at passport control
  const tabr = [23, 22, 22, 21, 20, 19, 18, 19, 20, 20, 21, 22, 23] // chart 3 time at baggage reclaim

  const avtp = [16, 15, 15, 14, 13, 13, 12, 13, 13, 14, 15, 15, 16] // chart 4 average time spent

  const vCrater = [1850, 1895, 1875, 1702, 1609, 1513, 1487, 1480, 1855, 1707, 1633, 2028, 2183] // chart 6 crater
  const vEleph = [2670, 2351, 2526, 2148, 1963, 2203, 2064, 2223, 2214, 2196, 2257, 2586, 2374] // chart 6 crater
  const vHisto = [11174, 10667, 10549, 9835, 9210, 8841, 8125, 9464, 9097, 9445, 9867, 10907, 11449] // chart 6 historical
  const vMaraya = [3936, 3479, 3462, 3360, 3479, 3363, 2644, 2827, 3259, 3425, 3644, 3252, 3614] // chart 6 maraya
  const vOldTown = [9244, 8647, 7965, 7684, 7511, 7454, 6952, 7632, 7264, 7781, 7866, 8271, 8774] // chart 6 old town

  const [chart1Options, setChart1Options] = useState(lineChartOptionsDashboard);
  const [chart1Data, setChart1Data] = useState(lineChartDataDashboard);

  const [chart2Options, setChart2Options] = useState(stackedBarChartOptionsDashboard);
  const [chart2Data, setChart2Data] = useState(stackedBarChartDataDashboard);


  const [chart5Options, setChart5Options] = useState(barChartOptionsDashboard2);
  const [chart5Data, setChart5Data] = useState(barChartDataDashboard2);

  const [chart3Options, setChart3Options] = useState(stackedBarChartOptionsDashboard2);
  const [chart3Data, setChart3Data] = useState(stackedBarChartDataDashboard2);

  const [chart4Options, setChart4Options] = useState(horizontalBarChartOptionsDashboard);
  const [chart4Data, setChart4Data] = useState(horizontalBarChartDataDashboard);

  const [chart6Options, setChart6Options] = useState(stackedBarChartOptionsDashboard3);
  const [chart6Data, setChart6Data] = useState(stackedBarChartDataDashboard3);

  const borderRadius = 0; // cards' border radius

  const handleStartDate = (event) => {
    const inputDate = event;

    // Validate the input date
    if (!(inputDate instanceof Date) || isNaN(inputDate)) {
      console.warn('Invalid date. Please select a valid date.');
      return;
    }

    // Format the date as MM/YYYY
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const year = inputDate.getFullYear();

    // Ensure the year is within the specified range (Dec/2022 to Dec/2023)
    if (year < 2022 || year > 2023) {
      console.warn('Invalid year. Please select a year between 2022 and 2023.');
      return;
    }

    // Calculate the month index
    let monthIndex = null;
    if(year === 2023){
      monthIndex = Number(month);
    }else if (year === 2022){
      monthIndex = 0;
    }
    console.log('Month Index:', monthIndex);
    setSMonthIndex(monthIndex);
    setSMonth(inputDate)
  };

  const handleEndDate = (event) => {
    const inputDate = event;

    // Validate the input date
    if (!(inputDate instanceof Date) || isNaN(inputDate)) {
      console.warn('Invalid date. Please select a valid date.');
      return;
    }

    // Format the date as MM/YYYY
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const year = inputDate.getFullYear();

    // Ensure the year is within the specified range (Dec/2022 to Dec/2023)
    if (year < 2022 || year > 2023) {
      console.warn('Invalid year. Please select a year between 2022 and 2023.');
      return;
    }

    // Calculate the month index
    let monthIndex = null;
    if(year === 2023){
      monthIndex = Number(month);
    }else if (year === 2022){
      monthIndex = 0;
    }
    console.log('Month Index:', monthIndex);
    setEMonthIndex(monthIndex);
  };
  
  useEffect(() => {
    // Function to generate new x-axis categories for line chart
    const generateXAxisCategories = () => {
      const now = new Date();
      const categories = [];

      for (let i = 11; i >= 0; i--) {
        const time = new Date((now.getTime() - 7*60*1000) - i * 60 * 60 * 1000);
        const formattedTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        categories.push(formattedTime);
      }

      setChart1Options((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: categories,
        },
      }));
    };

    // Function to generate new x-axis categories for line chart
    const generateStackedData = () => {
      const now = new Date();
      const time = new Date((now.getTime() - 7*60*1000));
      console.log("time: ", time)
      const monthI = time.getMonth();
      console.log(monthI)
      const hourI = time.getHours();
      console.log(hourI)
      let mWeight = [
        5+0.2*(Math.random()-0.5)*5,
        4+0.2*(Math.random()-0.5)*4,
        3+0.2*(Math.random()-0.5)*3,
        2+0.2*(Math.random()-0.5)*2,
        1+0.2*(Math.random()-0.5)*1,
        1+0.2*(Math.random()-0.5)*1,
        1+0.2*(Math.random()-0.5)*1,
        1+0.2*(Math.random()-0.5)*1,
        2+0.2*(Math.random()-0.5)*2,
        3+0.2*(Math.random()-0.5)*3,
        4+0.2*(Math.random()-0.5)*4,
        5+0.2*(Math.random()-0.5)*5,
      ]
      let dWeight = [
        0.1+0.2*(Math.random()-0.5)*0.1, 0.15+0.2*(Math.random()-0.5)*0.15,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1,
        3+0.2*(Math.random()-0.5)*3, 2+0.2*(Math.random()-0.5)*2,
        1+0.2*(Math.random()-0.5)*1, 1+0.2*(Math.random()-0.5)*1,
        2+0.2*(Math.random()-0.5)*2, 3+0.2*(Math.random()-0.5)*3,
        4+0.2*(Math.random()-0.5)*4, 3+0.2*(Math.random()-0.5)*3,
        1+0.2*(Math.random()-0.5)*1, 1+0.2*(Math.random()-0.5)*1,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1
      ]
      setChart2Data((prev) => 
        prev.map((item, index) => 
          index === 0 ? {...item, data: [
            Math.ceil(2*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)),
            Math.ceil(23*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)),
            Math.ceil(0*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)),
            Math.ceil(0*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)),
          ]} 
          : index === 1 ? {...item, data: [
            Math.ceil(5*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)),
            Math.ceil(41*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)),
            Math.ceil(8*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)),
            Math.ceil(0*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)),
          ]}
          : {...item, data: [
            Math.ceil(1*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)),
            Math.ceil(4*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)),
            Math.ceil(1*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)),
            Math.ceil(1*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)),
          ]}
        )
      );
      console.log("Hello World!");
    };

    const maraya = []
    const histo = []
    const eleph = []
    const crater = []
    // Function to generate new data every 7 minutes
    const generateData = () => {
      let mWeight = [
        5+0.2*(Math.random()-0.5)*5,
        4+0.2*(Math.random()-0.5)*4,
        3+0.2*(Math.random()-0.5)*3,
        2+0.2*(Math.random()-0.5)*2,
        1+0.2*(Math.random()-0.5)*1,
        1+0.2*(Math.random()-0.5)*1,
        1+0.2*(Math.random()-0.5)*1,
        1+0.2*(Math.random()-0.5)*1,
        2+0.2*(Math.random()-0.5)*2,
        3+0.2*(Math.random()-0.5)*3,
        4+0.2*(Math.random()-0.5)*4,
        5+0.2*(Math.random()-0.5)*5,
      ]
      let dWeight = [
        0.1+0.2*(Math.random()-0.5)*0.1, 0.15+0.2*(Math.random()-0.5)*0.15,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1,
        3+0.2*(Math.random()-0.5)*3, 2+0.2*(Math.random()-0.5)*2,
        1+0.2*(Math.random()-0.5)*1, 1+0.2*(Math.random()-0.5)*1,
        2+0.2*(Math.random()-0.5)*2, 3+0.2*(Math.random()-0.5)*3,
        4+0.2*(Math.random()-0.5)*4, 3+0.2*(Math.random()-0.5)*3,
        1+0.2*(Math.random()-0.5)*1, 1+0.2*(Math.random()-0.5)*1,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1
      ]

      for (let i = 11; i >= 0; i--) {
        const nowI = new Date((new Date().getTime() - 7 * 60 * 1000) - i*60*60*1000);
        const categories = [];
        const monthI = nowI.getMonth();
        const hourI = nowI.getHours();
        maraya.push(Math.ceil(23*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)));
        histo.push(Math.ceil(165*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)))
        eleph.push(Math.ceil(18*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)))
        crater.push(Math.ceil(7*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)))      
        // console.log("month: ", monthI, "hour: ", hourI)
      }

      setChart1Data((prev) => 
        prev.map((item, index) => 
          index === 0 ? {...item, data: maraya} 
          : index === 1 ? {...item, data: histo}
          : index === 2 ? {...item, data: eleph}
          : {...item, data: crater}
        )
      );
      // console.log(maraya, crater, eleph, histo)
      // console.log("values: ", maraya, histo, eleph, crater)

      
    };

    const generateNewData = () => {
      let mWeight = [
        5+0.2*(Math.random()-0.5)*5,
        4+0.2*(Math.random()-0.5)*4,
        3+0.2*(Math.random()-0.5)*3,
        2+0.2*(Math.random()-0.5)*2,
        1+0.2*(Math.random()-0.5)*1,
        1+0.2*(Math.random()-0.5)*1,
        1+0.2*(Math.random()-0.5)*1,
        1+0.2*(Math.random()-0.5)*1,
        2+0.2*(Math.random()-0.5)*2,
        3+0.2*(Math.random()-0.5)*3,
        4+0.2*(Math.random()-0.5)*4,
        5+0.2*(Math.random()-0.5)*5,
      ]
      let dWeight = [
        0.1+0.2*(Math.random()-0.5)*0.1, 0.15+0.2*(Math.random()-0.5)*0.15,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1,
        3+0.2*(Math.random()-0.5)*3, 2+0.2*(Math.random()-0.5)*2,
        1+0.2*(Math.random()-0.5)*1, 1+0.2*(Math.random()-0.5)*1,
        2+0.2*(Math.random()-0.5)*2, 3+0.2*(Math.random()-0.5)*3,
        4+0.2*(Math.random()-0.5)*4, 3+0.2*(Math.random()-0.5)*3,
        1+0.2*(Math.random()-0.5)*1, 1+0.2*(Math.random()-0.5)*1,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1,
        0.1+0.2*(Math.random()-0.5)*0.1, 0.1+0.2*(Math.random()-0.5)*0.1
      ]
      const nowI = new Date((new Date().getTime() - 7 * 60 * 1000));
      const monthI = nowI.getMonth();
      const hourI = nowI.getHours();
      maraya.shift()
      maraya.push(Math.ceil(23*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)))
      histo.shift()
      histo.push(Math.ceil(165*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)))
      eleph.shift()
      eleph.push(Math.ceil(18*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)))
      crater.shift()
      crater.push(Math.ceil(7*mWeight[monthI]/Math.max(...mWeight)*dWeight[hourI]/Math.max(...dWeight)))

      setChart1Data((prev) => 
        prev.map((item, index) => 
          index === 0 ? {...item, data: maraya} 
          : index === 1 ? {...item, data: histo}
          : index === 2 ? {...item, data: eleph}
          : {...item, data: crater}
        )
      );
    }

    // Generate initial categories
    generateXAxisCategories();
    generateData();
    generateStackedData();

    // Set up interval to update categories every minute
    const interval1 = setInterval(() => {
      generateXAxisCategories();
      generateNewData();
    }, 60000*60); // 60,000 milliseconds = 1 minute

    const interval2 = setInterval(() => {
      generateStackedData();
    }, 60000*7); // 60,000 milliseconds = 1 minute
    

    // Clean up the interval on component unmount
    return () => {
      clearInterval(interval1)
      clearInterval(interval2)
    };
  }, []); // Empty dependency array ensures useEffect runs only once on mount

  // update start month, end month when ever slide range changed
  useEffect(() => {
    setSMonthIndex(value[0])
    setEMonthIndex(value[1])
  }, [value])
  // Effect to update state2 based on state1
  useEffect(() => {
    // when ever start & end months changes update categroy state
    if (sMonthIndex >= 0 && sMonthIndex <= 12 && eMonthIndex >= 0 && eMonthIndex <13){
      const newCats = months.slice(sMonthIndex, eMonthIndex + 1);

      //chart 5
      const newBus = bus.slice(sMonthIndex, eMonthIndex + 1);
      const newTxi = taxi.slice(sMonthIndex, eMonthIndex + 1);

      // Chart 3
      const newTapc = tapc.slice(sMonthIndex, eMonthIndex + 1);
      const newTabr = tabr.slice(sMonthIndex, eMonthIndex + 1);

      // chart 4
      const newAvtp = avtp.slice(sMonthIndex, eMonthIndex + 1);

      // chart 6
      const newVCrater = vCrater.slice(sMonthIndex, eMonthIndex + 1);
      const newVEleph = vEleph.slice(sMonthIndex, eMonthIndex + 1);
      const newVHisto = vHisto.slice(sMonthIndex, eMonthIndex + 1);
      const newVMaraya = vMaraya.slice(sMonthIndex, eMonthIndex + 1);
      const newVOldTown = vOldTown.slice(sMonthIndex, eMonthIndex + 1);

      setChart5Options((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: newCats,
        },
      }));
      setChart3Options((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: newCats,
        },
      }));

      setChart4Options((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: newCats,
        },
      }));

      setChart6Options((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: newCats,
        },
      }));

      setChart5Data((prev) => 
        prev.map((item, index) => 
          index === 0 ? {...item, data: newBus} : {...item, data: newTxi}
        )
      );

      setChart3Data((prev) => 
        prev.map((item, index) => 
          index === 0 ? {...item, data: newTapc} : {...item, data: newTabr}
        )
      );

      setChart4Data((prev) => 
        prev.map((item, index) => 
          ({...item, data: newAvtp})
        )
      );

      setChart6Data((prev) => 
        prev.map((item, index) => 
          index === 0 ? {...item, data: newVCrater} 
          : index === 1 ? {...item, data: newVEleph}
          : index === 2 ? {...item, data: newVHisto}
          : index === 3 ? {...item, data: newVMaraya}
          : {...item, data: newVOldTown}
        )
      );
     const newSMonth = new Date(2022, 11+sMonthIndex, 1)
     setSMonth(newSMonth) 
     const newEMonth = new Date(2022, 11+eMonthIndex, 1)
     setEMonth(newEMonth) 
    }
  }, [sMonthIndex, eMonthIndex]); // Run this effect whenever state1 changes


  return (
    <DashboardLayout>
      <DashboardNavbar month={month} setMonth={setMonth} />
      <VuiBox py={3}>
      <VuiBox mb={3}>
      <Grid container spacing={2} justifyContent="flex-start" marginBottom={0}>
        <Grid item xs={12} sm={12} md={6} data-aos="fade-right">
        <Typography variant="h4" sx={{ mb: 0, color: 'white !important', textShadow: '0px 3px 10px rgba(0, 0, 0, 1)' }}>
            Live Excursions monitor
          </Typography>
          <VuiTypography variant="button" color="white" fontWeight="regular">
            Real-time excursions monitoring
          </VuiTypography>
        </Grid>
        
        </Grid>
      </VuiBox>
      
      <VuiBox mb={3}>
      <Grid container spacing={2} justifyContent="flex-start" marginBottom={3}>
        <Grid item xs={12} sm={12} md={8} sx={{transition: 'height 2s ease-in-out'}}>
          <Card sx={{borderRadius: borderRadius, transition: 'height 2s ease-in-out'}} data-aos="fade-right">
            <VuiBox sx={{ height: "340px" }}>
              <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                Number of persons at a site
              </VuiTypography>
              <VuiBox display="block" alignItems="center" mb="5px">
                <VuiTypography variant="button" color="text" fontWeight="regular">
                    ~ 7 minutes delay.
                </VuiTypography> <br />
              </VuiBox>
              <VuiBox sx={{marginTop: 0, height: '80%' }}>
              <LineChart 
                lineChartData={chart1Data}
                lineChartOptions={chart1Options}
              />
              </VuiBox>
            </VuiBox>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} data-aos="fade-left">
          <Card sx={{borderRadius: borderRadius}}>
            <VuiBox sx={{ height: "340px" }}>
              <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
              Grouping patterns
              </VuiTypography>
              <VuiBox sx={{ height: "85%", justifyContent: 'center', alignItems: 'center', textAlign: 'left'}}>
              <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: '100%',}}>
                    Updated 7 minutes ago
              </VuiTypography>
                <BarChart 
                  barChartData={chart2Data}
                  barChartOptions={chart2Options}
                />
              </VuiBox>
            </VuiBox>
          </Card>
        </Grid>
      </Grid>
      </VuiBox>

      <VuiBox mb={3}>
      <Grid container spacing={2} justifyContent="flex-start" marginBottom={0}>
        <Grid item xs={7} sm={7} md={6} data-aos="fade-right">
        <Typography variant="h4" sx={{ mb: 0, color: 'white !important', textShadow: '0px 3px 10px rgba(0, 0, 0, 1)' }}>
            Arrival experience
          </Typography>
          <VuiTypography variant="button" color="white" fontWeight="regular">
            Arrival and information experience
          </VuiTypography>
        </Grid>

        <Grid item xs={5} sm={5} md={6} data-aos="fade-left" sx={{textAlign: 'right'}}>
        {/* <RangeSlider /> */}
        <Calendar 
          value={sMonth}
          onChange={(e) => handleStartDate(e.value)}
          view="month" 
          dateFormat="mm/yy" 
          showIcon={true}
          inputId="start_month"
          style={{padding: 4}}
          placeholder="Starting month"
          minDate={new Date(2022, 11)}
          maxDate={new Date(2023, 11)}
        />
        <Calendar 
          value={eMonth}
          onChange={(e) => handleEndDate(e.value)}
          view="month" 
          dateFormat="mm/yy" 
          showIcon={true}
          inputId="end_month"
          style={{marginLeft:2, padding: 4}}
          placeholder="Ending month"
          minDate={new Date(2022, 11)}
          maxDate={new Date(2023, 11)}
        />

        <VuiBox sx={{display: 'flex', justifyContent: 'right', textAlign: 'right'}}>
          <RangeSlider value={value} setValue={setValue} />
        </VuiBox>
        </Grid>
        
        </Grid>
      </VuiBox>

      <VuiBox mb={3}>
      <Grid container spacing={2} justifyContent="flex-start" marginBottom={3} sx={{height: 'auto'}}>
        <Grid item xs={12} sm={12} md={8} sx={{transition: 'height 2s ease-in-out'}}>
          <Card sx={{borderRadius: borderRadius, transition: 'height 2s ease-in-out', marginBottom: 2, height: 'auto'}} data-aos="fade-right">
            <VuiBox sx={{ height: "300px" }}>
              <VuiTypography variant="lg" color="white" fontWeight="bold" mb="0px">
                Airport experience
              </VuiTypography>
              <VuiBox sx={{marginTop: 0, height: '95%' }}>
              <BarChart 
                barChartData={chart3Data}
                barChartOptions={chart3Options}
              />
              </VuiBox>
            </VuiBox>
          </Card>
          <Card sx={{borderRadius: borderRadius, transition: 'height 2s ease-in-out'}} data-aos="fade-right">
            <VuiBox sx={{ height: "300px", textAlign: 'left' }}>
              <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                Journey to lodging destination
              </VuiTypography>
              <VuiBox sx={{marginTop: 0, height: '80%' }}>
              <BarChart 
                barChartData={chart5Data}
                barChartOptions={chart5Options}
              />
              </VuiBox>
            </VuiBox>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} data-aos="fade-left">
          <Card sx={{borderRadius: borderRadius, height: isSmallScreen ? "600px" : "100%"}}>
            <VuiBox sx={{ height: "80%" }}>
              <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
              Visitor center experience
              </VuiTypography>
              <VuiBox sx={{ height: "100%", justifyContent: 'center', alignItems: 'center', textAlign: 'left'}}>
              <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: '100%',}}>
                    Time spent (minutes).
              </VuiTypography>
                <BarChart 
                  barChartData={chart4Data}
                  barChartOptions={chart4Options}
                />
                <VuiBox sx={{justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: '100%', textAlign: 'center'}}>
                    Average time spent (minutes).
              </VuiTypography>
              </VuiBox>
              </VuiBox>
            </VuiBox>
          </Card>
        </Grid>
      </Grid>
      </VuiBox>

      <VuiBox mb={3}>
      <Grid container spacing={2} justifyContent="flex-start" marginBottom={3} sx={{height: 'auto'}}>
        <Grid item xs={12} sm={12} md={12} data-aos="fade-up">
          <Card sx={{borderRadius: borderRadius, height: "750px"}}>
            <VuiBox sx={{ height: "90%" }}>
              <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
              Visitor counts
              </VuiTypography>
              <VuiBox sx={{ height: "100%", justifyContent: 'center', alignItems: 'center', textAlign: 'left'}}>
              <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: '100%',}}>
                    Visitor count tracking.
              </VuiTypography>
                <BarChart 
                  barChartData={chart6Data}
                  barChartOptions={chart6Options}
                />
                {/* <VuiBox sx={{justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: '100%', textAlign: 'center'}}>
                    Average time spent (minutes).
              </VuiTypography>
              </VuiBox> */}
              </VuiBox>
            </VuiBox>
          </Card>
        </Grid>
      </Grid>
      </VuiBox>

      </VuiBox> 
      
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
