export const horizontalBarChartOptionsDashboard = {
    chart: {
      type: 'bar',
      height: "100%"
    },
    tooltip: {
      enabled: true,
      theme: 'dark'
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        horizontal: true,
    }
    },
    colors: '#B59500',
    dataLabels: {
      enabled: true
    },
    xaxis: {
      categories: [],
      labels: {
        enabled: false,
        style: {
          colors: '#dfe6e9', // Set x-axis text color to white
        },
      },  
    },
    grid: {
        borderColor: 'transparent'
      },
    yaxis: {
      labels: {
        style: {
          colors: '#dfe6e9', // Set x-axis text color to white
        },
      },
      max: 20, 
    },
  }