/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

export const barChartDataDashboard2 = [
  {
    name: "Bus",
    data: [47, 45, 44, 0, 39, 38, 0, 0, 0, 0, 43, 45, 46],
  },
  {
    name: "Taxi / Car",
    data: [0, 0, 0, 30, 0, 0, 25, 27, 28, 29, 0, 0, 0],
  },
];
